<template>
  <div class="container-fluid padding-top-layout">
    <div class="wrap">
      <div class="wrap__block" :class="{'wrap__block-select': optionSelectMulti.value}">
        <div class="wrap__block__header wrap__header">
          <div class="wrap__block__header__content">
            <h1 class="wrap-title">{{ $t("list_all.title_registration_of_emissions") }}</h1>
            <div class="helper-icon">
              <img
                :src="getSettingIcon('helper-icon.svg')"
                @mouseover="changeTooltipImage($event)"
                @mouseleave="changeTooltipImage($event, 'mouseleave')"
                alt=""
              />
              <div
                v-if="showTooltip"
                class="helper-icon tooltip"
                v-html='$t("list_all.tooltip_title")'
              ></div>
            </div>
          </div>
        </div>
         <!-- <div v-show="optionSelectMulti.value" class="text-guide">
          {{ $t('multiple_approval.label_note_select') }}
        </div>  -->
        <div v-show="!optionSelectMulti.value" class="wrap__status">
          <div class="wrap__status__item register-btn">
            <div class="select-data">
              <v-select
                :items="listActionMulti"
                v-model="optionSelectMulti"
                item-text="text"
                item-value="value"
                :placeholder="$t('multiple_approval.placeholder_select_type')"
                dense
                hide-details
                solo
                return-object
                no-data-text="''"
                @change="handleChangeOptionMulti"
                class="select-type select-default list-menu--select select-item custom-placeholder-color"
                :class="{ 'disable-select': isEmptySource }"
                :menu-props="{ contentClass: 'select-menu export-select' }"
                :disabled="isEmptySource"
              >         
              </v-select>
            </div>
            <common-button class="register-button" :class="{'focus-button' : showRegisterPulldown}" v-ripple="false" @action="showRegisterPulldownEvent()" :disabled="!isRedirectDuration">
              <div class="register-button_warp">
                <img src="@/assets/icons/addregistericon.svg" alt="" class="addregistericon" />
                <span>{{ $t("list_all.button_sign_up") }}</span>
              </div>
              <div class="register-button_pulldown" :class="isShowMethodSelect && 'open-pulldown'">
                <img class="icon-detail btn-icon" src="@/assets/images/products/pulldownIcon.svg" alt="">
                <img class="icon-detail btn-icon_hover" src="@/assets/images/products/pulldownIcon_active.svg" alt="">
              </div>
            </common-button>
            <div v-if="showRegisterPulldown" class="register-pulldown">
              <div class="pulldown-item" :class="(disabledTemplateButton && i === 1) ? 'disabled' : ''" v-for="(item, i) in listPullDown" :key="i" @click="redirectHandler(item.text)" :id="item.id">
                <img v-if="disabledTemplateButton && i === 1" :src="getSettingIcon('csv-input/template-disable.svg')" alt="" class="pulldown-icon" />
                <img v-else :src="getSettingIcon(item.icon)" alt="" class="pulldown-icon" />
                <div class="pulldown-text">{{ item.text }}</div>
              </div>
            </div> 
          </div>
        </div>
        <div>
         
        <div v-show="optionSelectMulti.value" class="wrap__action">
          <common-button
              class="submit-register-btn mr-5"
              :label="$t('popup.button_cancel')"
              @action="clearSelect"
              v-ripple="false"
            />
            <common-button
              class="submit-register-btn"
              :disabled="!totalItemSelect"
              :label="getLabelButtonSubmit"
              type="colored"
              @action="submitData"
              v-ripple="false"
            />
        </div>
        </div>
        
      </div>
      <div
        class="category-table main-table custom-table new-register view-table"
        :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
      >
        <data-table
          :data-source="listData"
          :grid-columns="gridColumns"
          :init-grid="initializeGrid"
          :rowFocus="1"
          :showFocus="false"
          :allowAddNew="false"
          :isShowToolbar="false"
          :isEmptySource="isEmptySource"
          :labelEmpty="messageLabelEmpty"
          :isResizeCustome="false"
          :bigCheckboxes="true"
          @changeFullScreen="onChangeFullScreen"
          @onHandlerSelecteFilterColumn="onHandlerSelecteFilterColumn"
        />
      </div>
      <comment-log-popup :dialog="commentPopup" :contents="commentHistoryList" @close="commentPopup = false" />
      <history-popup :dialog="historyPopup" :historyLogList="historyLogList" @close="closePopup" />
    </div>
    <div ref="scrollElement" >
      <div class="return__button-responsive" :class="{'fixed-button-bottom': isFixedButton}">
        <div v-show="optionSelectMulti.value" class="item-responsive">
          <common-button
              class="mb-4"
              :disabled="!totalItemSelect"
              :label="getLabelButtonSubmit"
              type="colored"
              @action="submitData"
              v-ripple="false"
            />
          <common-button
              class="submit-register-btn mr-5"
              :label="$t('popup.button_cancel')"
              @action="clearSelect"
              v-ripple="false"
            />   
        </div>
       
        <div v-show="!optionSelectMulti.value" class="item-responsive register-btn">
          <common-button class="register-button" :class="{'focus-button' : showRegisterPulldown}" v-ripple="false" @action="showRegisterPulldownEvent()">
            <div class="register-button_warp">
              <img src="@/assets/icons/addregistericon.svg" alt="" class="addregistericon" />
              <span>{{ $t("list_all.button_sign_up") }}</span>
            </div>
            <div class="register-button_pulldown" :class="isShowMethodSelect && 'open-pulldown'">
              <img class="icon-detail btn-icon" src="@/assets/images/products/pulldownIcon.svg" alt="">
              <img class="icon-detail btn-icon_hover" src="@/assets/images/products/pulldownIcon_active.svg" alt="">
            </div>
          </common-button>
          <div v-if="showRegisterPulldown" class="register-pulldown">
            <div class="pulldown-item" :class="(disabledTemplateButton && i === 1) ? 'disabled' : ''" v-for="(item, i) in listPullDownSp" :key="i"  @click="redirectHandler(item.text)">
              <img v-if="disabledTemplateButton && i === 1" :src="getSettingIcon('csv-input/template-disable.svg')" alt="" class="pulldown-icon" />
              <img v-else :src="getSettingIcon(item.icon)" alt="" class="pulldown-icon" />
              <div class="pulldown-text">{{ item.text }}</div>
            </div>
          </div> 
          <div v-show="!optionSelectMulti.value" class="select-data register-btn mt-4">
            <v-select
              :items="listActionMulti"
              v-model="optionSelectMulti"
              item-text="text"
              item-value="value"
              :placeholder="$t('multiple_approval.placeholder_select_type')"
              dense
              hide-details
              solo
              return-object
              no-data-text="''"
              @change="handleChangeOptionMulti"
              class="select-type select-default list-menu--select select-item custom-placeholder-color"
              :menu-props="{ contentClass: 'select-menu export-select export pulldown-year' }"
              :class="{ 'disable-select': isEmptySource }"
              :disabled="isEmptySource"
            />
          </div>
        </div>
      </div>
    </div>
    <notification-popup :dialog="dialogNotification" :message="messageSavedChange" @submit="dialogNotification = false" />
    <notification-popup :dialog="showPopupDurationSuccess" :message="message" @submit="closeDialogSuccess"/>
    <submit-data-popup
        :dialog="submitDataDialogPopup"
        :listApprovers="listApprovers"
        :isShowTitle="false"
        :isShowUserApproval="isShowUserApproval"
        @submitHandler="submitDataAproval"
        @closeDialogSubmitData="submitDataDialogPopup = false"
    > 
    <slot>
      <div class="text-custom">
        {{ $t('multiple_approval.description_item_selected', {number: totalItemSelect}) }}
      </div>
    </slot>
  </submit-data-popup>

  <QuestionPopup
      :dialog="dialogStatusPopup"
      :message="$t('multiple_approval.description_confirm_cancel_submitted', {number: totalItemSelect })"
      isSaveButtonDelete
      :confirmText="$t('register_data.button_cancel_request')"
      @submit="submitDataHandler"
      @close="dialogStatusPopup = false"
    />

    <QuestionPopup
      :dialog="showErrorChangeStatus"
      :message="$t('register_data.popup_message_duplicate_data')"
      isSaveButtonDelete
      :confirmText="$t('list_menu.label_decision')"
      @submit="submitDataHandler(true)"
      @close="showErrorChangeStatus = false"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { CollectionView } from "@mescius/wijmo";
import DataTable from "@/components/category/data-table";
import { ROUTES } from "@/router/constants";
import { KEYS_CODE } from "@/constants/keyboard";
import * as wjGrid from "@mescius/wijmo.grid";
import * as wjcCore from "@mescius/wijmo";
import { CellMaker } from "@mescius/wijmo.grid.cellmaker";
import CommonButton from "@/components/utils/button.vue";
import HistoryPopup from "@/components/dialogs/history-popup.vue";
import CommentLogPopup from '@/components/dialogs/log-confirm-popup';
import NotificationPopup from '@/components/dialogs/notification-popup';
import QuestionPopup from '@/components/dialogs/question-popup';
import ButtonCommonCustome from "@/components/commonApp/ButtonCustome.vue";

import { getListWorkflowApi, getListLogHistory, getListCommentHistory } from "@/api/registerData";
import { getStatusNameById, getColorStatusById, getColorTextStatusById } from "@/utils/status";
import { formatDateTimeDataTable } from '@/utils/datetimeFormat';
import { getDataLayer, getScopeName, getCategoryName } from "@/utils/registerData";
import { updateDataBeforeRedirect } from "@/utils/updateDataBeforeRedirect";
import { REGISTER_PULLDOWN, LIST_PULLDOWN } from '@/constants/registerData';
import { $_helper_isNumberType, formatValue } from '@/concerns/newRegisterData/wijmo.helper';
import i18n from '@/lang/i18n.js'
import { getWidthOfDetailButton } from '@/utils/multiLanguage'
import { getWidthByText } from '@/utils/getWidthByText';
import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';
import { STATUS_DRAFT, STATUS_SUBMIT } from '@/constants/status';
import SubmitDataPopup from '@/components/dialogs/ActionHeaderTable/submit-data-popup';
import { getAutoApprovalSetting } from '@/api/autoApproval';
import { APPROVAL_STATUS } from '@/constants/registerData'
import { updateStatusMultipleData } from '@/api/newRegisterData';
import { STATUS_FIELD } from '@/constants/status';

export default {
  name: "ListTemplates",
  data() {
    return {
      items           : [
        {
          text    : this.$t("list_all.hyperlink_home"),
          disabled: false,
          href    : ROUTES.HOME_DASHBOARD
        },
        {
          text    : this.$t("list_all.label_registration_of_emissions"),
          disabled: false,
          href    : ROUTES.EMISSIONS
        }
      ],
      message:this.$t('list_all.message_change_success'),
      duration        : "",
      flex            : null,
      listEmissionData: [],
      listData        : null,
      durationId      : null,
      undoStack       : null,
      canUndo         : false,
      canRedo         : false,
      commentPopup    : false,
      historyPopup    : false,
      gridColumns     : [],
      showTooltip     : false,
      historyLogList     : [],
      commentHistoryList: [],
      dialogNotification: false,
      messageNotification: this.$t('b_list_all.message_notification'),
      isRedirectDuration: true,
      disabledTemplateButton: false,
      showPopupDurationSuccess: false,
      showRegisterPulldown: false,
      showErrorChangeStatus: false,
      isShowMethodSelect: false,
      selectedFilterColumn: null,
      isEmptySource: true,
      isFixedButton: true,
      selectedItems: [],
      listActionMulti: [
        {
          text: this.$t('multiple_approval.label_select_status_draf'),
          column_name: 'status_daft',
          data_bidding: 'is_status_daft',
          value: 1
        },
        {
          text: this.$t('multiple_approval.label_select_status_submit'),
          column_name: 'status_submit',
          data_bidding: 'is_status_submit',
          value: 2
        }
      ],
      optionSelectMulti: {},
      submitDataDialogPopup: false,
      dialogStatusPopup: false,
      isShowUserApproval: true,
      totalItemSelect: 0,
      isEditTitle: false,
      isSubmitDataDuplicate: false,
      dataApproval: {},
      isCheckboxHeaderStatusSubmitDisable: true,
      isCheckboxHeaderStatusDaftDisable: true,
      isRedirectToListSubmit: false,
    };
  },
  components: {
    DataTable,
    CommonButton,
    CommentLogPopup,
    HistoryPopup,
    NotificationPopup,
    SubmitDataPopup,
    QuestionPopup,
    ButtonCommonCustome
  },
  async mounted() {
    this.actionUpdateIsLoadingTable(true);
    await this.updateBreadCrumb(this.items); 
    await Promise.all([this.getTemplateData(false),this.getApproversList(), this.getTemplateList()]).then(() => {
    }).finally(_ => {
      this.actionUpdateIsLoadingTable(false)
      this.actionUpdateIsLoading(false)
    })
    await this.handleGetSettingWorkFollow();
    this.showPopupDurationSuccess = this.isChangedDurationSuccess;
    this.actionUpdateIsChangedDurationSuccess(false)
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    this.actionDisableMenu(false);
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    ...mapState("commonApp", ["loadMore", "isFilter", "isExpand"]),
    ...mapState("registerData", ["isFullScreen",'isChangedDurationSuccess']),
    ...mapState("userData", ["currentUser"]),
    ...mapState('approval', ['listApprovers']),
    isAdmin() {
      return this.currentUser?.user?.role_id === 3;
    },
    registerPulldown() {
      return Object.values(REGISTER_PULLDOWN);
    },
    listPullDown() {
      return LIST_PULLDOWN
    },
    listPullDownSp() {
      return LIST_PULLDOWN.slice(0, 2)
    },
    messageLabelEmpty() {
      return this.$t("list_all.description_label_empty");
    },
    messageSavedChange() {
      return this.$t('facility_management.message_saved_changes')
    },
    getLabelButtonSubmit(){
      return this.optionSelectMulti.column_name === 'status_daft' ? this.$t('multiple_approval.button_submit_data_draf', {number: this.totalItemSelect}) : this.$t('multiple_approval.button_submit_data_submitted', {number: this.totalItemSelect})
    },
  },
  watch: { 
    totalItemSelect(newVal) {
      this.actionDisableMenu(newVal > 0)
    }
  },
  methods : {
    ...mapActions("commonApp", ["updateBreadCrumb",'actionUpdateIsLoading', "actionUpdateIsLoadingTable"]),
    ...mapActions("registerData", ["updateDataScope", "updateDataCategory", "updateDataMonth", "updateDuration", "updateListDuration","actionUpdateIsChangedDurationSuccess","actionCheckIvalueDurationSuccess"]),
    ...mapActions("newRegisterData", ["updateIsApproved"]),
    ...mapActions('approval', ['getApproversList']),
    ...mapActions("settingApp", ["actionDisableMenu"]),
    ...mapActions('workflowData', ['getNotification']),
    handleScroll() {
      const scrollElementTop = this.$refs.scrollElement.offsetTop; // Vị trí của phần tử
      const scrollPosition = window.scrollY + window.innerHeight; // Vị trí scroll hiện tại
      const elPlus = this.optionSelectMulti.value ? -56 : 56;
      if (scrollPosition > (scrollElementTop + 295 + elPlus)) { //position btn currently
        this.isFixedButton = false;
      } else {
        this.isFixedButton = true;
      }
      this.setPositionPullDown();
    },
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return "";
    },
    redirectToCalculationTerm() {
      this.actionCheckIvalueDurationSuccess(true);
      return this.$router.push({ path: ROUTES.CALCULATION_TERM });
    },
    redirectToTemplate() {
      return this.$router.push({ path: "/emissions/" + ROUTES.LIST_EMISSION_TEMPLATES }).catch(() => {
      });
    },
    initializeGrid(flexgrid) {
      this.flex = flexgrid;

      document.addEventListener("keydown", (event) => {
        if (
          (event.metaKey || event.ctrlKey) &&
          [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW].includes(
            event.keyCode
          )
        ) {
          event.preventDefault();
        }
      });

      flexgrid.hostElement.addEventListener(
        "keydown",
        (event) => {
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col);
              flexgrid.selection     = cellRange;

              // re-select after add more
              setTimeout(() => {
                flexgrid.selection = cellRange;
              }, 200);
            } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection     = new wjGrid.CellRange(0, currentSelection.col);
            } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection     = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1);
            } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection     = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }
        },
        false
      );
      flexgrid.hostElement.addEventListener('mouseover',(e)=>{
        if(e.target.parentElement.classList.contains('comment-icon')){
          e.target.src = require(`@/assets/icons/comment_active.svg`);
        }
      });
      flexgrid.hostElement.addEventListener('mouseout',(e)=>{
        if(e.target.parentElement.classList.contains('comment-icon')){
        e.target.src = require(`@/assets/icons/comment.svg`);
        }
      });
      this.flex.onSelectionChanged(null);
    },
    async onCommentClicking(rowData) {
      if (!rowData.item?.id) return;
      await this.handleGetCommentHistory(rowData.item?.id);
      this.commentPopup    = true;
      this.dialogCancelBtn = true;
    },
    async onHistoryClicking(rowData) {
      if (!rowData.item?.id) return;
      this.workflowDataId  = rowData.item?.id;
      await this.handleGetHistoryChanged(rowData.item?.id);
      this.historyPopup    = true;
      this.dialogCancelBtn = true;
    },
    async handleGetHistoryChanged(workflowDataId) {
      const payload = {
        id: workflowDataId
      }
      const listHistoryChanged = await getListLogHistory(payload);
      this.historyLogList = listHistoryChanged.data.map(listHistoryChanged => {
          return {
            ...listHistoryChanged,
            date: formatDateTimeDataTable(listHistoryChanged.updated_at),
            name: listHistoryChanged.content,
            status: getStatusNameById(listHistoryChanged.status),
            color: getColorStatusById(listHistoryChanged.status)
          }
        })
    },
    async handleGetCommentHistory(workflowDataId) {
      const payload = {
        id: workflowDataId
      }
      const commentList = await getListCommentHistory(payload);
      this.commentHistoryList = commentList.data.map(commentHistory => {
          return {
            ...commentHistory,
            date: formatDateTimeDataTable(commentHistory.created_at),
            name: commentHistory.user.name,
            content: commentHistory.content,
          }
        })
    },
    changeTooltipImage(event, type) {
      if (type === "mouseleave") {
        event.target.src                        = this.getSettingIcon("helper-icon.svg");
        event.target.parentElement.style.cursor = "default";
        this.showTooltip                        = false;
      } else {
        event.target.src                        = this.getSettingIcon("helper-icon_active.svg");
        event.target.parentElement.style.cursor = "pointer";
        this.showTooltip                        = true;
      }
    },
    async getTemplateData(isReloadList) {
      this.listEmissionData = [];
      const payload = {
        action: "listAll"
      };
      getListWorkflowApi(payload).then((res) => {
      if(res.data.length <= 5) {
        this.isFixedButton = false;
      }
      res.data.forEach((item) => {
        this.listEmissionData.push({
          id               : item.id,
          data_id      : item.id,
          status           : item.status === null ? "" :getStatusNameById(item.status),
          title            : item.title || null,
          comment          : "",
          company_name     : item.contractor?.name,
          scope            :getScopeName(parseInt(item.scope)),
          category_name    :getCategoryName(parseInt(item.scope), parseInt(item.category)) || null,
          month_year_registration: `${item.year}/${String(item.month).padStart(2, '0')}`,
          last_modified    : formatDateTimeDataTable(item.updated_at),
          iconComment      : item.comment_count > 0 ? require(`@/assets/icons/comment.svg`) : '',
          date_submit      : formatDateTimeDataTable(item.submitted_at),
          date_approval    : formatDateTimeDataTable(item.approved_at),
          layer1           : getDataLayer(item.scope, item.category, item.pattern_id)?.method || null,
          layer2           : getDataLayer(item.scope, item.category, item.pattern_id)?.sub_method || null,
          log_history      : this.$t('list_all.button_log_display'),
          approved_by: item.approved_by,
          duration_id: item.duration_id,
          scope_id: parseInt(item.scope),
          category_id: parseInt(item.category),
          month: item.month,
          pattern_id: item.pattern_id,
          colorStatus: getColorTextStatusById(item.status),
          is_status_daft: item.status === STATUS_DRAFT,
          is_status_submit: item.status === STATUS_SUBMIT,
          status_daft: false,
          status_submit: false,
          status_id: item.status,
          contractor_id: item?.contractor_id
        });
      });
      if(isReloadList) {
        this.listData.sourceCollection = this.listEmissionData;
      } else {
        this.defineTable();
      }
      }).finally(_ =>{
        this.actionUpdateIsLoadingTable(false)
      });
    },
    defineTable() {
      this.gridColumns       = this.getGridColumns();
      this.listData          = new CollectionView([...this.listEmissionData], {
        trackChanges: true,
        sortComparer: (a, b) => {
          if (this.selectedFilterColumn !== 'category_name') return null;
          const valueA = a?.split('カテゴリ')[1]?.trim();
          const valueB = b?.split('カテゴリ')[1]?.trim();
          if ($_helper_isNumberType(valueA) && $_helper_isNumberType(valueB)) {
            const numA = Number(formatValue(valueA));
            const numB = Number(formatValue(valueB));
            return numA - numB;
          }
          return null;
        }
      });
      this.flex.columnGroups = this.getGridColumns();
      this.listData.currentItem = null;
      this.flex.formatItem.addHandler((s, e) => {
        if (s.columns[e.col].binding === 'status' && s.cells.cellType === wjGrid.CellType.Cell) {
          let rowValue = s.rows[e.row]?._data;
          e.cell.classList.add(rowValue?.colorStatus);
        }

        if ((s.columns[e.col].binding === 'status_daft' || s.columns[e.col].binding === 'status_submit') && e.panel == s.columnHeaders) {
            const checkbox = document.createElement('input');
            checkbox.type = 'checkbox';
            checkbox.classList.add('wj-cell-check-custome')

            const self = this;
            checkbox.checked = self.selectAllChecked();
            checkbox.addEventListener('click', _ => {
              // Custom click event logic
              this.flex.beginUpdate();
              this.flex.rows.forEach(row => {
                if (row.dataItem && row.dataItem[this.optionSelectMulti.data_bidding]) {
                  if (checkbox.checked) {
                    // push item id to this.selectedItems
                    self.selectedItems.push({
                      id: row?.dataItem.id,
                      name: row?.dataItem.name,
                    });
                  }
                  row.dataItem[this.optionSelectMulti.column_name] = checkbox.checked; // update checkbox element
                }
              });
              if (checkbox.checked) {
                // remove duplicate row
                self.removeDuplicateData(self.selectedItems);
              } else {
                // remove item id in this.selectedItems
                self.selectedItems = [];
              }
              this.totalItemSelect = this.listData.sourceCollection.filter(row => row[this.optionSelectMulti.column_name]).length;
              this.flex.endUpdate();
            });
            e.cell.appendChild(checkbox);
        }

        if(s.columns[e.col].binding === this.optionSelectMulti.column_name && e.panel != s.columnHeaders) {
          const rowData = s.rows[e.row].dataItem;
          const isDisable = !rowData[this.optionSelectMulti.data_bidding];
          if(isDisable) {
            wjcCore.addClass(e.cell, "wj-cell-background-disabled");
            wjcCore.toggleClass(e.cell, "wj-state-disabled");
          }

          const checkLeastOneItemChecked = s.rows.some((row) => {
            return row._data[this.optionSelectMulti.data_bidding];
          });

          if (checkLeastOneItemChecked) {
            if (this.optionSelectMulti.column_name === 'status_submit') {
              this.isCheckboxHeaderStatusSubmitDisable = false;
            } else {
              this.isCheckboxHeaderStatusDaftDisable = false;
            }
          }
        }

        if (s.columns[e.col].binding === this.optionSelectMulti.column_name && e.panel == s.columnHeaders) {
          if (this.optionSelectMulti.column_name === 'status_submit') {
            if (this.isCheckboxHeaderStatusSubmitDisable) {
              wjcCore.toggleClass(e.cell, "wj-state-disabled");
            }
          } else {
            if (this.isCheckboxHeaderStatusDaftDisable) {
              wjcCore.toggleClass(e.cell, "wj-state-disabled");
            }
          }
        }

        if(s.columns[e.col].binding === 'data_id' && e.panel !== s.columnHeaders) {
          const totalItemSelect = this.listData.sourceCollection.filter(row => row[this.optionSelectMulti.column_name]).length;
          if(totalItemSelect > 0) {
            wjcCore.toggleClass(e.cell, "wj-state-disabled");
          }
        }
      });

      this.flex.cellEditEnded.addHandler((s, e) => {
        this.totalItemSelect = this.listData.sourceCollection.filter(row => row[this.optionSelectMulti.column_name]).length

        const col = e.panel.columns[e.col];
        if (col.binding === 'title') {
          this.isEditTitle = true;
        }
      })

      this.flex.beginningEdit.addHandler((sender, event) => {
        const column = sender.columns[event.col];

        const listColumnNotDisable = [
          "data_id",
          "log_history",
          "status_submit",
          "status_daft"
        ];
        if (!listColumnNotDisable.includes(column.binding)) {
          event.cancel = true;
        }
      });
      this.flex.pastingCell.addHandler((s, e) => {
        const col = e.panel.columns[e.col];
        if(col.binding === 'status_daft' || col.binding === 'status_submit') {
          e.cancel = true;
        }
      })

      this.listData.collectionChanged.addHandler((s, e) => {
        this.resetCheckboxHeaderStatus();
        const checkLeastOneItemChecked = s._view.some((row) => {
          return row[this.optionSelectMulti.data_bidding];
        });

        if (checkLeastOneItemChecked) {
          if (this.optionSelectMulti.column_name === 'status_submit') {
            this.isCheckboxHeaderStatusSubmitDisable = false;
          } else {
            this.isCheckboxHeaderStatusDaftDisable = false;
          }
        }
      });

      setMinMaxSizeColumns(this.flex, this.listEmissionData);
      this.$nextTick(() => {
        this.scrollToTop();
      });
      this.isEmptySource= this.listData?.items?.length <= 0;
    },

    async handleGetSettingWorkFollow() {
      try {
        const response = await getAutoApprovalSetting()
        this.isShowUserApproval = !response.data.auto_approve
      } catch (error) {
        console.warn(error);
      }
    },

    handleChangeOptionMulti(){
      if(this.optionSelectMulti.column_name === 'status_daft') {
        this.flex.getColumn(this.optionSelectMulti.column_name).visible = true;
        this.flex.getColumn('status_submit').visible = false;
      } else {
        this.flex.getColumn(this.optionSelectMulti.column_name).visible = true;
        this.flex.getColumn('status_daft').visible = false;
      }
    },

    selectAllChecked() {
      const totalRows = this.flex.rows.filter(row => row.dataItem && row.dataItem[this.optionSelectMulti.data_bidding]);
      const totalRowsChecked = totalRows.filter(row => row.dataItem[this.optionSelectMulti.column_name] === true);
      return totalRows.length === totalRowsChecked.length && totalRows.length !== 0 && totalRowsChecked.length !== 0
        ? true
        : false;
    },
    removeDuplicateData(arr) {
      this[arr] = [...new Map(arr.map((item) => [item['id'], item])).values()];
    },
    resetCheckboxHeaderStatus() {
      this.isCheckboxHeaderStatusSubmitDisable = true;
      this.isCheckboxHeaderStatusDaftDisable = true;
    },
    clearSelect() {
      this.flex.getColumn(this.optionSelectMulti.column_name).visible = false;
      this.flex.sourceCollection = this.listEmissionData.map(item => {
        item[this.optionSelectMulti.column_name] = false;
        return item;
      });
      this.optionSelectMulti = {};
      this.selectedItems = [];
      this.totalItemSelect = 0;
      this.submitDataDialogPopup = false;
      this.dialogStatusPopup = false;
      this.showErrorChangeStatus = false;
    },

    submitData() {
      if(this.optionSelectMulti.column_name === 'status_daft') {
        this.submitDataDialogPopup = true;
      } else {
        this.dialogStatusPopup = true;
      }
    },

    submitDataAproval(approvalFormData) {
      this.dataApproval = approvalFormData
      this.submitDataHandler(false)
    },

    submitDataHandler(isSubmitDuplidate) {
      const payload = {
        action: this.optionSelectMulti.column_name === 'status_daft' ? 'submit' : 'cancel_submit',
        workflowIds: this.listData.items.filter(item => item[this.optionSelectMulti.column_name]).map(item => item.id),
        selected_approver: this.isShowUserApproval ? this.dataApproval?.selected_approver : null,
        comment: this.dataApproval?.comment,
        check_duplicate: isSubmitDuplidate ? 0 : 1
      }

      this.actionUpdateIsLoadingTable(true)
      updateStatusMultipleData(payload).then(res => {
        if(res.success) {
          this.message = this.optionSelectMulti.column_name === 'status_daft' ? this.$t("register_data.popup_message_submit_sucess") : this.$t("register_data.popup_message_cancel_submit");
          this.isRedirectToListSubmit = this.optionSelectMulti.column_name === 'status_daft'
          this.clearSelect();
          this.showPopupDurationSuccess = true;
          this.getNotification();
          this.getTemplateData(true);
          this.resetCheckboxHeaderStatus();
        }
      }).catch(err => {
        this.submitDataDialogPopup = false;
        this.dialogStatusPopup = false;
        if(err.errors.error_type && err.errors.error_type.includes('duplicate')) {
          this.showErrorChangeStatus = true;
        } else {
          this.showPopupDurationSuccess = true;
          this.message = err.errors?.error_msg?.join(",");
        }
       
        this.actionUpdateIsLoadingTable(false)
      })
    },
    closeDialogSuccess() {
      this.showPopupDurationSuccess = false;
      const pathName = this.isShowUserApproval ? 'EmissionsListSubmitted' : 'Approval';
      if(this.isRedirectToListSubmit) {
        this.$router.push({name: pathName});
      }
    },

    getGridColumns() {
      return [
        {
          header      : "#",
          binding     : "id",
          allowSorting: false,
          isReadOnly  : true,
          visible     : false
        },
        {
          header      : " ",
          binding     : "data_id",
          minWidth    : getWidthOfDetailButton(i18n.locale),
          maxWidth    : getWidthOfDetailButton(i18n.locale),
          allowSorting: false,
          isRequired  : false,
          cssClassAll : "btn-db table-id hide-filter",
          cellTemplate: CellMaker.makeButton({
            text : this.$t('list_all.button_edit'),
            click: (event, context) => this.onIdClicking(context)
          })
        },
        {
          header: " ",
          binding: "status_daft",
          cssClassAll: "hide-filter column-checkbox",
          minWidth: 40,
          maxWidth: 40,
          dataType: 'Boolean',
          allowSorting: false,
          isRequired: false,
          wordWrap: true,
          visible: false,
        },
        {
          header: " ",
          binding: "status_submit",
          cssClassAll: "column-checkbox hide-filter",
          minWidth: 40,
          maxWidth: 40,
          dataType: 'Boolean',
          allowSorting: false,
          isRequired: false,
          wordWrap: true,
          visible: false,
        },
        {
          header: this.$t('list_all.table_status'),
          binding: "status",
          minWidth: getWidthByTextContent(this.$t('list_all.table_status')),
          maxWidth: 350,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          cssClassAll : "history-btn",
          cellTemplate: (ctx) => (`<span class="${ctx.item.colorStatus}">${ctx.item.status}</span>`),
        },
        {
          header      : " ",
          binding     : "iconComment",
          minWidth    : 32,
          maxWidth    : 32,
          allowSorting: false,
          isRequired  : false,
          cssClass    : "btn-db",
          cellTemplate: CellMaker.makeImage({
            click: (event, context) => this.onCommentClicking(context)
          }),
          cssClassAll : "height34 cell-comment hide-filter comment-icon"
        },
        {
          header      : this.$t('list_all.table_title'),
          binding     : "title",
          minWidth    : getWidthByTextContent(this.$t('list_all.table_title')),
          maxWidth    : 980,
          allowSorting: false,
          isReadOnly  : true,
          multiLine   : true,
          wordWrap    : true,
          isRequired  : false,
        },
        {
          header      : this.$t('list_all.table_company_name'),
          binding     : "company_name",
          minWidth    : getWidthByTextContent(this.$t('list_all.table_company_name')),
          maxWidth    : 980,
          allowSorting: false,
          isRequired  : false,
          isReadOnly  : true,
          multiLine   : true,
          wordWrap    : true,
        },
        {
          header      : this.$t('list_all.table_scope'),
          binding     : "scope",
          minWidth    : getWidthByTextContent(this.$t('list_all.table_scope')),
          maxWidth    : 200,
          allowSorting: false,
          isRequired  : false,
          isReadOnly  : true,
          multiLine   : true,
          wordWrap    : true,
        },
        {
          header      : this.$t('list_all.table_category'),
          binding     : "category_name",
          minWidth    : getWidthByTextContent(this.$t('list_all.table_category')),
          maxWidth    : 200,
          allowSorting: false,
          isRequired  : false,
          isReadOnly  : true,
          multiLine   : true,
          wordWrap    : true,
        },
        {
          header : this.$t('list_all.table_calculation_method'),
          align  : "center",
          columns: [
            {
              header      : this.$t('list_all.table_layer1'),
              binding     : "layer1",
              minWidth    : getWidthByTextContent(this.$t('list_all.table_layer1')) + 5,
              maxWidth    : 980,
              allowSorting: false,
              isRequired  : false,
              isReadOnly  : true,
              wordWrap    : true,
            },
            {
              header      : this.$t('list_all.table_layer2'),
              binding     : "layer2",
              minWidth    : getWidthByTextContent(this.$t('list_all.table_layer2')) + 5,
              maxWidth    : 980,
              allowSorting: false,
              isRequired  : false,
              isReadOnly  : true,
              wordWrap    : true,
            }
          ]
        },
        {
          header      : this.$t('list_all.table_month_year_registration'),
          binding     : "month_year_registration",
          minWidth    : getWidthByTextContent(this.$t('list_all.table_month_year_registration')),
          maxWidth    : 200,
          allowSorting: false,
          isRequired  : false,
          isReadOnly  : true,
          multiLine   : true,
          wordWrap    : true,
        },
        {
          header      : this.$t('list_all.table_last_modified'),
          binding     : "last_modified",
          minWidth    : getWidthByTextContent(this.$t('list_all.table_last_modified')),
          maxWidth    : 200,
          allowSorting: false,
          isRequired  : false,
          isReadOnly  : true,
          multiLine   : true,
          wordWrap    : true,
        },
        {
          header      : this.$t('list_all.table_date_submit'),
          binding     : "date_submit",
          minWidth    : getWidthByTextContent(this.$t('list_all.table_date_submit')),
          maxWidth    : 200,
          allowSorting: false,
          isRequired  : false,
          isReadOnly  : true,
          multiLine   : true,
          wordWrap    : true,
        },
        {
          header      : this.$t('list_all.table_date_approval'),
          binding     : "date_approval",
          minWidth    : getWidthByTextContent(this.$t('list_all.table_date_approval')),
          maxWidth:  300,
          allowSorting: false,
          isReadOnly  : true,
          cssClassAll : "",
          multiLine   : true,
          wordWrap    : true,
        },
        {
          header      : this.$t('list_all.table_log_history'),
          binding     : "log_history",
          minWidth    : getWidthByText(this.$t('list_all.table_log_history')),
          width    : '*',
          allowSorting: false,
          cssClass    : "btn-db",
          cssClassAll : "cell_comment-confirm hide-filter",
          multiLine   : true,
          wordWrap    : true,
          cellTemplate: (ctx, el) => {
            const button = CellMaker.makeButton({
              text: ctx.item.log_history ? ctx.item.log_history : this.$t('contractor.button_plan_choice'),
              click: (e, ctx) => this.onHistoryClicking(ctx),
            });

            return button(ctx, el);
          },
        },
      ];
    },
    scrollToTop() {
      if (!this.flexGrid) {
        return;
      }

      let rc                       = this.flexGrid.cells.getCellBoundingRect(0, 0, true);
      this.flexGrid.scrollPosition = new wjcCore.Point(this.flexGrid.scrollPosition.x, -rc.top);
    },
    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.scrollToTop();
      } else {
        this.initialView();
      }
    },
    closePopup() {
      this.commentPopup = false;
      this.historyPopup = false;
    },
    async onIdClicking(rowData) {
      if (!rowData.item?.id) return;
      // return
      // await Promise.all([
      //   this.updateDataScope(rowData.item.scope_id),
      //   this.updateDataCategory(rowData.item.category_id),
      //   this.updateDataMonth(rowData.item.month),
      //   this.updateIsApproved(rowData.item.approved_by === null ? false : true),
      //   this.updateDuration(rowData.item.duration_id),
      // ]);
      await updateDataBeforeRedirect(rowData.item);
      this.$router.push({ path: `/emissions/register/list-menu/${rowData.item.id}` });
    },
    async getTemplateList() {
      const payload          = {
        action       : "listTemplate",
        contractor_id: this.currentUser.user.contractor_id
      };    
      const templateResponse = await getListWorkflowApi(payload);
      this.disabledTemplateButton = templateResponse.data.length > 0 ? false : true;
    },
    showRegisterPulldownEvent() {
      this.isShowMethodSelect = !this.isShowMethodSelect
      this.showRegisterPulldown = !this.showRegisterPulldown;
      this.setPositionPullDown();
    },
    setPositionPullDown() {
      const dropdown = document.querySelector(".item-responsive");
      const rect = dropdown.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      this.$nextTick(() => {
        const dropdownMenu = document.querySelector(".item-responsive .register-pulldown");
        if (dropdownMenu) {
          if (spaceBelow <= dropdownMenu.clientHeight) {
            dropdownMenu.style.top = "auto";
            dropdownMenu.style.bottom = "100%";
          } else {
            dropdownMenu.style.top = "96px";
            dropdownMenu.style.bottom = "auto";
            }
        }
      });
    },
    redirectHandler(item) {
      this.showRegisterPulldown = false;
      switch (item) {
        case REGISTER_PULLDOWN.CREATE_NEW:
          this.$router.push({ path: "/emissions/" + ROUTES.LIST_MENU_EMISSION }).catch(() => {});
          break;
        case REGISTER_PULLDOWN.CREATE_FROM_TEMPLATE:
          this.$router.push({ path: "/emissions/" + ROUTES.LIST_EMISSION_TEMPLATES }).catch(() => {});
          break;
        default:
          this.$router.push({ path: "/emissions/" + ROUTES.LIST_MENU_EMISSION + '/?csv=true' }).catch(() => {});
          break;
      }
    },
    onHandlerSelecteFilterColumn(column) {
      this.selectedFilterColumn = column;
    }
  }
};
</script>

<style lang="scss">
.wj-flexgrid .wj-cell-check-custome[type=checkbox]:checked {
  line-height: 1em;
}

.wj-flexgrid .wj-cell-background-disabled {
  background-color: $monoLight !important;
}

.category-table .wj-header.wj-colgroup.column-checkbox.wj-align-center:not(.status-field) {
  border-bottom: 1px solid rgba(42, 42, 48, 0.1) !important;
}

.custom-placeholder-color input::placeholder {
  color: $monoBlack!important;
}

.select-durations {
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    background: $monoOffWhite;
  }
  &::-webkit-scrollbar-thumb {
    background: $bgMid;
    border-radius: 6px;
    z-index: 1000;
    border-left: 4px solid $monoOffWhite;
    border-right: 4px solid $monoOffWhite;
  }
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;
  max-height: 280px !important;
  margin-top: 45px !important;
  .theme--light.v-list {
    background: $monoOffWhite;
    .v-list-item {
      border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      border-right: 1px solid rgba(42, 42, 48, 0.1);
      min-height: 40px;
      gap: 4px;
      .v-list-item__content {
        padding: 8px 0;
      }
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background: $bgCusLight;
      }
    }
  }
}
</style>
<style scoped lang="scss">
@import './styles/index.scss';
.return__button-responsive.fixed-button-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
}
.template-detail-header {
  .wj-btn {
    display: none;
  }
}

.wj-flexgrid .wj-cell-maker {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $monoBlack;
  // font-family: 'Source Han Sans JP';
}

.wj-cell {
  .wj-cell-maker {
    background: none;
    justify-content: start;
  }

  .wj-cell-maker:hover {
    background: none;
    color: $monoBlack;
  }
}

.duration-wrapper {
  .duration-text {
    color: $monoBlack;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    margin-bottom: 8px;
  }

  .calculation-page {
    display: flex;
    align-items: center;
    background-color: $bgLight;
    padding: 15px;
    border-radius: 4px;
    height: 56px;

    div {
      margin-right: 20px;

      .duration-content {
        color: $monoBlack;
        font-weight: 700;
        font-size: 11px;
        line-height: 26px;
        letter-spacing: 0.03em;
        padding: 5px 0px 0px 0px;
      }

      .duration-text {
        color: $goldMid;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0.03em;
      }
    }
  }
}

.template-detail-header {
  .wj-cell-maker {
    margin-top: 2px;
    width: 26px;
    height: 20px;
    font-style: normal;
    // font-family: 'Source Han Sans JP';
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    color: $goldMid;
    text-align: center;
    background: none;
    border: 1px solid $goldLight;
  }

  .wj-cell-maker:hover {
    color: $monoWhite;
    background: #0072a3;
  }
}
.hide-filter {
  .wj-btn {
    display: none;
  }
}
.custom-table {
  @media (max-width: $tablet) {
    padding-left: 20px !important;
  }
}
.wrap {
  width: 100%;
  height: 100%;

  &__status {
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    padding-right: 0;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      padding-right: 0px;
    }

    &__item {
      display: none;

      &__wrap {
        margin-left: 12px;

        p {
          color: $monoBlack;
          margin: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          letter-spacing: 0.05em;

          &:nth-child(1) {
            font-style: normal;
            font-weight: 700;
            font-size: 11px;
            line-height: 18px;
            letter-spacing: 0.03em;
          }
        }
      }
    }

    .duration-wrapper {
      width: 100%;

      .duration {
        .calculation-page {
          justify-content: space-between;
        }
      }
    }
  }

  &__action {
    display: none;
  }

  &__header {
    margin-bottom: 48px;
    margin-top: 40px;

    @media (max-width: $tablet) {
      margin-left: 0px;
    }

    &--p1 {
      max-width: 700px;
    }

    p {
      margin: 0px;
      font-weight: 400;
      font-size: 22px;
      line-height: 36px;
      letter-spacing: 0.05em;
      color: $goldMid;
      @media (max-width: 768px) {
        font-size: 22px;
        line-height: 28px;
      }
    }

    p:last-child {
      padding-top: 48px;
      color: $monoBlack !important;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
    }
  }

  &__block-select {
    justify-content: space-between;
    .wrap__block__header {
      flex: none!important;
    }
    .text-guide {
      color: $monoDark;
      font-family: "Source Han Sans JP";
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 184.615% */
      letter-spacing: 0.65px;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    padding: 0 0 40px;
    align-items: start;
    @media (max-width: 768px) {
      padding-left: 0px;
      flex-direction: column;
    }

    &__header {
      flex: 1 0;
      padding-bottom: 58px;

      &__content {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      span {
        margin: 0 16px 0 0;
        font-weight: 400;
        font-size: 22px;
        line-height: 36px;
        letter-spacing: 0.05em;
        color: $goldMid;
        @media (max-width: 768px) {
          font-size: 22px;
          line-height: 28px;
          margin-right: 8px;
        }
      }
    }

    .wrap__status,
    .wrap__header {
      margin-bottom: 0px;
    }

    .wrap__status__item {
      span {
        margin-left: 8px;
      }
    }
  }
}
.return__button-responsive.fixed-button-bottom {
  .item-responsive {
    width: 90%;
  }
}
.return {
  &__button-responsive {
    width: calc(100% + 40px);
    display: flex;
    flex-direction: column;
    background: $bgMid;
    box-shadow: 0px -3.13px 2.86674px rgba(160, 181, 186, 0.165596), 0px -1.24px 1.13351px rgba(160, 181, 186, 0.24);
    padding: 16px 20px;
    position: relative;
    bottom: -80px;
    margin-left: -20px;
   
    .item-responsive {
      width: 100%;
      margin: 0 auto;
      .button-custom {
        width: 100%;
        &.sp {
          margin-bottom: 16px;
        }
      }
      .common-btn {
        width: 100%;
        .common-btn-text {
          margin-left: 10px;
        }
        .addregistericon_active {
          display: none;
        }
        .addregistericon_disabled {
          display: none;
        }
        // &:hover {
        //   .v-btn__content {
        //     .addregistericon {
        //       display: none !important;
        //     }
        //     .addregistericon_active {
        //       display: block !important;
        //     }
        //     .addregistericon_disabled {
        //       display: none;
        //     }
        //   } 
        // }
        // &:active {
        //   .addregistericon {
        //     display: block;
        //   }
        //   .addregistericon_active {
        //     display: none;
        //   }
        //   .addregistericon_disabled {
        //     display: none;
        //   }
        // }
      }
      .register-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 7px 13px 9px 16px !important;
        &.focus-button {
          border: 2px solid $blueMid;
        }
        border: 2px solid transparent;
        &_warp {
          display: flex;
          gap: 10px;
        }
        &_pulldown {
          .btn-icon {
            display: block;
          }
          .btn-icon_hover {
            display: none; 
          }
          &.open-pulldown {
          transform: rotate(180deg);
          }
        }
        &:hover {
          background-color: $monoWhite !important;
          .register-button_warp {
            span {
              color: $monoBlack;
            }
          }
          .register-button_pulldown {
              .btn-icon {
                display: none;
              }
              .btn-icon_hover {
                display: block; 
              }
            }
        }
      }
      &.register-btn {
      .register-pulldown {
        width: 100%;
        z-index: 99;
        .pulldown-item {
          width: 100%;
        }
      }
    }
    }
  }
}

.register-btn {
  position: relative;
  .register-pulldown {
    position: absolute;
    width: 230px;
    max-height: 160px;
    background: $monoOffWhite;
    margin-top: 2px;
    border-radius: 4px;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    z-index: 98;
    top: 100%;
    right: 0;
    margin-bottom: 2px;
    .item-pulldown {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 7px 0px 9px 16px;
      border: 1px solid rgba(42, 42, 48, 0.1);
      color: $monoBlack;
      border-bottom: 0;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: "Source Han Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      margin-left: unset !important;
      &:hover {
        background: $bgLight;
      }
    }
    .pulldown-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 7px 16px 9px;
      gap: 8px;
      width: 230px;
      height: 40px;
      border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      cursor: pointer;
      &:last-child {
        border-bottom: unset;
      }
      &:hover {
        background: $bgLight;
      }
      .pulldown-text {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: $monoBlack;
        white-space: nowrap;
      }
      &.disabled {
        pointer-events: none;
        cursor: default;
        background: $monoLight !important;
        .pulldown-text {
          color: $monoMid;
          font-weight: 500;
        }
      }
      &:first-child {
        &:hover {
          border-top-left-radius: 4px ;
          border-top-right-radius: 4px;
        }
        &.disabled {
          border-top-left-radius: 4px ;
          border-top-right-radius: 4px;
        }
      }
      &:last-child {
        &:hover {
          border-bottom-left-radius: 4px ;
          border-bottom-right-radius: 4px;
        }
        &.disabled {
          border-bottom-left-radius: 4px ;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }
}
@include desktop {
  .wrap {
    &__header {
      margin-top: unset;
      margin-left: 40px;
    }

    &__status {
      padding-right: 40px;

      &__item {
        display: block;
        display: flex;
      }
    }

    &__action {
      margin-right:40px;
      display: block;

      .submit-register-btn {
        min-width: 180px;
      }
    }

    &__block {
      flex-direction: row;
      align-items: center;

      &__header {
        padding-bottom: 0;
      }
    }

    .wrap__block__header__content {
      display: flex;

      span {
        margin: 8px 10px 0px 0px;
      }

      // .helper-icon {
      //   margin: 16px 10px 0px 0px;
      // }
    }
    .wrap__status__item {
      .addregistericon_active {
        display: none;
      }
      .addregistericon_disabled {
        display: none;
      }
      .register-button {
        width: fit-content;
        min-width: 140px;
        display: flex;
        align-items: center;
        // padding: 0 !important;
        padding: 7px 15px 9px 18px;
        &.focus-button {
          border: 2px solid $blueMid;
        }
        border: 2px solid transparent;
        &_warp {
          display: flex;
          margin-right: 14px;
          align-items: center;
          .addregistericon {
            height: 20px;
          }
        }
        &_pulldown {
          // transition: all 0.3s ease-out;
          .btn-icon {
            display: block;
          }
          .btn-icon_hover {
            display: none; 
          }
          &.open-pulldown {
          transform: rotate(180deg);
          }
        }
        &:hover {
          background-color: $monoWhite !important;
          .v-btn__content {
            .register-button_warp {
              span {
                color: $monoBlack;
              }
            }
            .register-button_pulldown {
              .btn-icon {
                display: none;
              }
              .btn-icon_hover {
                display: block; 
              }
            }
          }
        }
      }
      // &.register-btn {
      //   left: -65px;
      // }
    }
  }
}

.return__listSubmitted {
  margin-right: 40px !important;

  .return__header {
    background: $monoOffWhite;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 161px;
    height: 40px;

    &:hover {
      background: $goldLight;

      svg path {
        stroke: #ffffff;
      }

      .return__text {
        color: $monoOffWhite;
      }
    }

    .action {
      cursor: pointer;
    }

    .return__text {
      // font-family: 'Source Han Sans JP';
      color: #404d50;
      font-size: 14px;
      letter-spacing: 0.03em;
      font-style: normal;
      line-height: 24px;
      font-weight: 500;
    }
  }
}
.template-register-button.disabled {
  pointer-events: none;
  cursor: default;
  background: $monoLight !important;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24) !important;

  .v-btn__content {
    span {
      color: $monoMid !important;
    }
    .addregistericon {
      display: none !important;
    }
    .addregistericon_active {
      display: none;
    }
    .addregistericon_disabled {
      display: block;
    }
  }
}

.select-data {
  .disable-select {
    ::v-deep {
      .v-input__control {
        .v-input__slot {
          .v-select__selections {
            input {
              &::placeholder {
                    color: $monoMid !important;
              }
            }
          }
        }
      }
    }
  }
}
@include desktop {
  .custom-table {
    padding-left: 0 !important;
  }
  .return {
    &__header {
      margin-top: unset;
      margin-left: 40px;
    }
  }
  .return__button-responsive {
    display: none;
  }
  .wrap {
    .wrap__block__header__content {
      .helper-icon {
        &.tooltip {
          position: absolute;
          width: 400px;
          max-width: unset;
        }
      }
    }
  }
  .register-btn {
    display: flex;
    gap: 20px;
    .select-data {
      width: 120px;
    }
  }
}

//custome style
@media screen and (min-width: 1024px) and (max-width: 1150px) {
  .wrap {
    &__block {
      flex-direction: column;

      &__header {
        padding-bottom: 58px;
      }
    }

    //&__status {
    //  padding-right: 0;
    //}
  }
}
</style>
